import styled from '@emotion/styled';
import React, { useRef, useEffect, useState } from 'react';
import colors from 'style/colors';
import { baseTransitionSecond, mq, easeOut } from 'style/variables';
import { fadeIn, slideIn, slideInActive } from 'style/mixin';

type TabButtonProps = {
  active: boolean;
  value: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Container = styled.button`
  position: relative;
  min-width: 8.1em;
  height: auto;
  padding: 7px 4px 4px;
  background-color: ${colors.white01};
  color: ${colors.black01};
  border-radius: 2px;
  letter-spacing: ${1.06 / 14}em;
  line-height: 1;
  overflow: hidden;
  cursor: pointer;
  ${mq.onlysp} {
    min-width: 10.5em;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    &::before {
    }
  }

  &.is-active {
    color: ${colors.white01};
    &::before {
      transform: scaleX(1);
      background-color: ${colors.black01};
    }
  }
`;

const IntroBg = styled.span`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.gray02};
  transform: scaleX(0);
  transform-origin: left;
  animation: ${slideIn} ${baseTransitionSecond * 2}s ease 0.6s both;
  .is-active & {
    background-color: ${colors.black01};
    animation: ${slideInActive} ${baseTransitionSecond * 2}s ease 0.6s both;
  }
  &.is-intro-end {
    display: none;
  }
`;

const HoverBg = styled.span`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.gray02};
  transform-origin: right;
  transition: background-color ${baseTransitionSecond}s ${easeOut}, transform ${baseTransitionSecond}s ${easeOut};
  transform: scaleX(0);
  .is-active & {
    transform: scaleX(1);
    background-color: ${colors.black01};
  }
  button:hover & {
    transform: scaleX(1);
    transform-origin: left;
  }
  &.is-intro-end {
    display: block;
  }
`;
const Text = styled.span`
  position: relative;
  opacity: 0;
  z-index: 10;
  body:not(.is-transition-animating) & {
    animation: ${fadeIn} ${baseTransitionSecond}s ease 0.3s both;
  }
`;

export const TabButton: React.FC<TabButtonProps> = props => {
  const [introEnd, setIntroEnd] = useState(false);
  const introbg = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (introbg.current) {
      introbg.current.addEventListener('animationend', setIntroEndFun);
    }
    return () => {
      if (introbg.current) {
        introbg.current.removeEventListener('animationend', setIntroEndFun);
      }
    };
  }, []);
  const setIntroEndFun = () => {
    setIntroEnd(true);
  };
  return (
    <Container {...props} className={props.active ? 'is-active' : ''} value={props.value} onClick={props.onClick}>
      <IntroBg className={introEnd ? 'is-intro-end' : undefined} ref={introbg}></IntroBg>
      <HoverBg className={introEnd ? 'is-intro-end' : undefined}></HoverBg>
      <Text>{props.children}</Text>
    </Container>
  );
};
