import React from 'react';
import styled from '@emotion/styled';
import { mq } from 'style/variables';
import sizes from 'style/sizes';
import { Tab } from 'components/molecules/tab';

type HasTabHeadingProps = {
  title?: string;
  tabs: Array<TabsObject>;
  marginBottom?: number;
  marginBottomSp?: number;
  callback?: (event: React.MouseEvent<HTMLElement>) => void;
};

type TabsObject = {
  text: string;
  value: string;
};

const Container = styled.div<HasTabHeadingProps>`
  margin-bottom: ${sizes.margin[80]};
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
    margin-bottom: ${sizes.margin[64]};
  }
`;

const TabWrapper = styled.div`
  ${mq.onlysp} {
    overflow: scroll;
    width: calc(100% + ${sizes.margin[32]} * 2);
    margin-left: -${sizes.margin[32]};
    padding: 0 ${sizes.margin[32]} 0;
  }
`;

export const HasTabHeading: React.FC<HasTabHeadingProps> = props => {
  return (
    <Container {...props}>
      <TabWrapper>
        <Tab callback={props.callback} tabs={props.tabs}></Tab>
      </TabWrapper>
    </Container>
  );
};
