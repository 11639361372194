import styled from '@emotion/styled';
import { TabButton } from 'components/atoms/tabButton';
import React, { useState } from 'react';
import fonts from 'style/fonts';
import sizes from 'style/sizes';
import { mq } from 'style/variables';

type TabProps = {
  tabs: Array<TabsObject>;
  callback?: (event: React.MouseEvent<HTMLElement>) => void;
};

type TabsObject = {
  text: string;
  value: string;
};

const Container = styled.ul`
  position: relative;
  display: flex;
  ${mq.onlypc} {
    justify-content: center;
  }
  ${mq.onlysp} {
    justify-content: flex-start;
    height: 20px;
    width: fit-content;
    padding-bottom: ${sizes.margin[48]};
  }
  & > li {
    font-size: 14px;
    font-family: ${fonts.enGothic};
    line-height: ${22 / 12}em;
    position: relative;
    ${mq.onlysp} {
      font-size: 13px;
      white-space: nowrap;
      line-height: ${20 / 13}em;
      &:last-of-type {
        padding-right: 25px;
      }
    }
    & + li {
      ${mq.onlypc} {
        margin-left: ${sizes.margin[32]};
      }
    }
  }
`;

export const Tab: React.FC<TabProps> = props => {
  const [activeIdx, setActiveIdx] = useState(0);
  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    setActiveIdx(Number(e.currentTarget.getAttribute('data-index')));
    if (props.callback) {
      props.callback(e);
    }
  };

  return (
    <Container {...props}>
      {props.tabs.map((item, idx) => (
        <li key={idx}>
          <TabButton onClick={e => clickHandler(e)} data-index={idx} value={item.value} active={activeIdx === idx}>
            {item.text}
          </TabButton>
        </li>
      ))}
    </Container>
  );
};
